import { AuthStateProvider } from "@/context/AuthState";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import Login from "../authentication/Login";
import UserSignUp from "../authentication/UserSignUp";
import InvitationScreen from "./InvitationScreen";
import WaitingList from "./WaitingList";

const BetaWidget = ({startScreen}: { startScreen: "invitation" | "signin" | "signup"}) => {
  const [authView, setAuthView] = useState<
    "invitation" | "signin" | "signup"
  >(startScreen);
  return (
    <AuthStateProvider>
      <AnimatePresence mode="wait">
        { authView === "signin" ? (
          <motion.div
            key="signin"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 20 }}
            transition={{ duration: 0.2 }}
          >
            <Login size="sm" switchView={() => setAuthView("invitation")} />
          </motion.div>
        ) : authView === "invitation" ? (
          <motion.div
            key="invitiation"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 20 }}
            transition={{ duration: 0.2 }}
          >
            {/* <UserSignUp size="sm" switchView={() => setAuthView("login")} /> */}
            <InvitationScreen
              size="sm"
              switchToSignin={() => setAuthView("signin")}
              switchToSignup={() => setAuthView("signup")}
            />
          </motion.div>
        ) : authView === "signup" ? (
          <motion.div
            key="signup"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 20 }}
            transition={{ duration: 0.2 }}
          >
            <UserSignUp size="sm" switchView={() => setAuthView("signin")} />
          </motion.div>
        ) : null}
      </AnimatePresence>
    </AuthStateProvider>
  );
};

export default BetaWidget;
