import React, { createContext } from "react";
import { useParams } from "react-router-dom";

import { V1_BACKEND_URL } from "../lib/utils";
import {
  IGroupedChatNavigationItem,
  NavigationItem,
  NewChat,
} from "../types/sidebar";
import { useGlobalState } from "./GlobalState";

interface ISideBarContext {
  navigationList?: NavigationItem[];
  sidebarOpen: boolean;
  newChat: NewChat | null;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  resetChatView: () => void;
}

const initialSideBarContextState: ISideBarContext = {
  navigationList: [],
  sidebarOpen: false,
  newChat: null,
  setSidebarOpen: () => {},
  resetChatView: () => {},
};

export const SideBarContext = createContext<ISideBarContext>(
  initialSideBarContextState,
);

export const useSideBar = () => React.useContext(SideBarContext);

export const SideBarProvider: React.FC<{
  children: React.ReactNode;
  initialChats: IGroupedChatNavigationItem[];
}> = ({ children }) => {
  const { session, setCurrentView, setOpenTabs } = useGlobalState();
  const { chatId } = useParams();
  const resetChatView = () => {
    setOpenTabs((prev) => {
      return prev.map((tab) => ({
        ...tab,
        isCurrent: false,
      }));
    });
    setCurrentView("chat");
  };

  const [newChat, setNewChat] = React.useState<NewChat | null>(null);
  const [sidebarOpen, setSidebarOpen] = React.useState(true);

  React.useEffect(() => {
    const { access_token } = session;
    if (!access_token) return;
    const sseEndpoint = `${V1_BACKEND_URL}/stream?token=${access_token}`;

    const eventSource = new EventSource(sseEndpoint);

    eventSource.onopen = () => {
      console.debug("es connected.");
    };

    eventSource.onmessage = (event) => {
      // @ts-ignore
      setStreamData((prevData) => [...prevData, event.data]);
    };

    eventSource.addEventListener("new-chat", (event) => {
      const data = JSON.parse(event.data);
      console.log("event data", data);
      const chatTitle = data.title;
      const chatPath = `/dashboard${data.path}`;

      setNewChat({
        name: chatTitle,
        href: chatPath,
      });
    });

    eventSource.onerror = (event) => {
      console.error("es error:", event);
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };
  }, [session]);

  const sharedState: ISideBarContext = {
    sidebarOpen,
    newChat,
    setSidebarOpen,
    resetChatView,
  };

  return (
    <SideBarContext.Provider value={sharedState}>
      {children}
    </SideBarContext.Provider>
  );
};
