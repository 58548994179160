import { IGroupedChatNavigationItem } from "@/types/sidebar";

interface Chat {
    id: string;
    title: string;
    path: string;
    created_at: number;
}
export const mapChatsToDiebarItems = (chats: Chat[]): IGroupedChatNavigationItem[] => {

    const groupedChats: IGroupedChatNavigationItem[] = [
        { time_period: "Today", chats: [] },
        { time_period: "Yesterday", chats: [] },
        { time_period: "This Week", chats: [] },
        { time_period: "This Month", chats: [] },
        { time_period: "Older", chats: [] },
    ];

    const appendChatToGroupedChats = (groupedChats: IGroupedChatNavigationItem[], time_period: string, chat: Chat): IGroupedChatNavigationItem[] => {
        for (let groupedChat of groupedChats) {
            if (groupedChat.time_period === time_period) {
                groupedChat.chats.push({ name: chat.title, href: `/dashboard${chat.path}` });
                return groupedChats;
            }
        }
        return groupedChats;
    };

    const todayDate = new Date().toISOString().split('T')[0];
    const yesterdayDate = new Date(Date.now() - 86400000).toISOString().split('T')[0];
    const startOfWeekDate = new Date(Date.now() - (new Date().getDay() * 86400000)).toISOString().split('T')[0];
    const startOfMonthDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split('T')[0];

    for (let chat of chats) {
        const chatDate = new Date(chat.created_at * 1000).toISOString().split('T')[0];

        if (chatDate === todayDate) {
            appendChatToGroupedChats(groupedChats, "Today", chat);
        } else if (chatDate === yesterdayDate) {
            appendChatToGroupedChats(groupedChats, "Yesterday", chat);
        } else if (chatDate >= startOfWeekDate) {
            appendChatToGroupedChats(groupedChats, "This Week", chat);
        } else if (chatDate >= startOfMonthDate) {
            appendChatToGroupedChats(groupedChats, "This Month", chat);
        } else {
            appendChatToGroupedChats(groupedChats, "Older", chat);
        }
    }

    // Remove all empty groups
    return groupedChats.filter(group => group.chats.length > 0);
}