import { ReactNode, useRef } from "react";
import {
  CalendarIcon,
  GTrendIcon,
  InsightsReportsIcon,
  JobsIcon,
  NewsPaperIcon,
  NotificaitonIcon,
  XIcon,
} from "../ui/icons";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";

interface GoogleTrendsConfig {
  keyword?: string;
  geo?: string;
  timeRange?: string;
  category?: number;
}

interface TwitterFeedConfig {
  embedId?: string;
  height?: string;
  width?: string;
}

const TwitterFeed: React.FC<TwitterFeedConfig> = ({
  embedId = "25501165",
  height = "350px",
  width = "100%",
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const buildTwitterURL = () => {
    return `https://widgets.sociablekit.com/twitter-list/iframe/${embedId}`;
  };

  return (
    <div className="rounded-lg bg-transparent">
      <iframe
        ref={iframeRef}
        src={buildTwitterURL()}
        className="h-[350px] w-full"
        title="Twitter Feed Widget"
        frameBorder="0"
      />
    </div>
  );
};

const GoogleTrendsWidget: React.FC<GoogleTrendsConfig> = ({
  keyword = "/m/02mxjp",
  geo = "US",
  timeRange = "now 7-d",
  category = 0,
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const buildTrendsURL = () => {
    const baseURL =
      "https://trends.google.com:443/trends/embed/explore/RELATED_QUERIES";

    const comparisonItem = [
      {
        keyword,
        geo,
        time: timeRange,
      },
    ];

    const requestParams = {
      comparisonItem,
      category,
      property: "",
      filter: "RISING_TOP",
    };

    const urlParams = new URLSearchParams({
      req: JSON.stringify(requestParams),
      tz: "-120",
      eq: `date=${encodeURIComponent(timeRange)}&geo=${geo}&q=${encodeURIComponent(keyword)}&hl=en`,
    });

    return `${baseURL}?${urlParams.toString()}`;
  };

  return (
    <div className="rounded-lg bg-transparent p-4">
      <iframe
        ref={iframeRef}
        src={buildTrendsURL()}
        className="h-[350px] w-full"
        title="Google Trends Widget"
        frameBorder="0"
      />
    </div>
  );
};

const RightSideBar: React.FC = () => {
  return (
    <div className="fixed right-0 top-0 h-full bg-white p-4 shadow-lg">
      <div className="mt-32 flex flex-col items-center justify-center space-y-6">
        <SideBarIcon>
          <NotificaitonIcon className="h-8 w-8 text-primary-500" />
        </SideBarIcon>
        <SideBarIcon>
          <NewsPaperIcon className="h-8 w-8 text-primary-500" />
        </SideBarIcon>
        <SideBarIcon>
          <CalendarIcon className="h-8 w-8 text-primary-500" />
        </SideBarIcon>
        <SideBarIcon>
          <InsightsReportsIcon className="h-8 w-8 text-primary-500" />
        </SideBarIcon>
        <SideBarIcon>
          <JobsIcon className="h-8 w-8 text-primary-500" />
        </SideBarIcon>
        <div className="relative">
          <Popover>
            <PopoverTrigger>
              <SideBarIcon>
                <XIcon className="h-6 w-6 text-primary-500" />
              </SideBarIcon>
            </PopoverTrigger>
            <PopoverContent
              className="h-[400px] w-[400px] p-0"
              side="left"
              sideOffset={20}
            >
              <TwitterFeed />
            </PopoverContent>
          </Popover>
        </div>
        <div className="relative">
          <Popover>
            <PopoverTrigger>
              <SideBarIcon>
                <GTrendIcon className="h-8 w-8 text-primary-500" />
              </SideBarIcon>
            </PopoverTrigger>
            <PopoverContent
              className="h-[400px] w-[400px] p-0"
              side="left"
              sideOffset={20}
            >
              <GoogleTrendsWidget />
            </PopoverContent>
          </Popover>
        </div>
      </div>
    </div>
  );
};

const SideBarIcon: React.FC<{ children: ReactNode; onClick?: () => void }> = ({
  children,
  onClick,
}) => {
  return (
    <div className="cursor-pointer" onClick={onClick}>
      {children}
    </div>
  );
};

export default RightSideBar;
