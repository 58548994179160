import { nanoid } from "ai";
import { redirect } from "react-router-dom";

import {
  getChatById,
  getChats,
} from "../requests";
import { getSession, getUser } from "../requests/supabase";
import { IGroupedChatNavigationItem } from "../types/sidebar";
import { fetchUserLimits, fetchUserMessageConsumption } from "../requests/v1";

export const checkIfLogginIn = async () => {
  const user = await getUser();

  if (window.location.pathname === "/auth/reset-password-form") {
    return null;
  }

  if (user) {
    const userMetada = user.user_metadata;
    if (!userMetada?.investor_type) {
      return { user };
    }
    return redirect("/dashboard");
  }

  return null;
};

export const checkIfLoggedOut = async () => {
  const user = await getUser();

  if (!user) {
    return redirect("/auth/login");
  }

  return null;
};

export const generateNewChatId = () => {
  const newChatId = nanoid();
  return redirect(`/dashboard/chat/${newChatId}`);
};

export const getChatMessages = async ({ params }: { params: any }) => {
  const { chatId } = params;
  const session = await getSession();

  if (!session) {
    return null;
  }

  const { access_token } = session;

  const messages = await getChatById(chatId, access_token);

  return { messages };
};

export const dashboardLoader = async () => {
  const session = await getSession();

  if (!session) {
    return redirect("/auth/login");
  }

  if (!session.user.confirmed_at) {
    return redirect("/auth/confirm-email");
  }

  const userMessageCount = await fetchUserMessageConsumption(
    session.user.id,
    session.access_token,
  );

  const currentUserLimits = await fetchUserLimits(
    session.user.id,
    session.access_token,
  );

  return { userMessageCount, currentUserLimits };
};
