import React, { useState } from "react";
import { useGlobalState } from "../../context/GlobalState";
import { useMutation } from "@tanstack/react-query";
import { deleteUserAccount } from "../../requests/v1";
import LoadingSpinner from "../../components/ui/Loading/LoadingSpinner";
import { SidebarTrigger } from "../chat/TopBar";

const AccountPage: React.FC = () => {
  const { user, signOut } = useGlobalState();

  const userPic =
    user?.user_metadata.picture ||
    `https://ui-avatars.com/api/?name=${user?.user_metadata.first_name}+${user?.user_metadata.last_name}`;

  const displayName =
    user?.user_metadata.name ||
    user?.user_metadata.first_name + " " + user?.user_metadata.last_name ||
    "";

  const handleDeleteAccount = () =>
    // @ts-ignore
    window.document.getElementById("my_modal_5").showModal();

  return (
    <div>
      <div className="ml-12 pl-2 pt-4">
        <SidebarTrigger />
      </div>
      <div className="mt-10 px-6">
        <ConfirmDeleteModal />
        <div className="mt-6">
          <div className="px-4 sm:px-6 lg:px-9">
            <h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
              Account Information
            </h3>
          </div>
          <div className="overflow-hidden border border-gray-900/10 lg:m-8 dark:border-gray-100/10">
            <div className="px-4 py-5 sm:p-0 dark:border-gray-100/10">
              <dl className="sm:divide-y sm:divide-gray-900/10 sm:dark:divide-gray-100/10">
                {userPic && (
                  <div className="flex justify-between py-4 sm:gap-4 sm:px-6 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500 dark:text-white">
                      Profile Picture
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-gray-400">
                      <img
                        className="h-12 w-12 rounded-full bg-gray-50"
                        src={userPic}
                        alt=""
                      />
                    </dd>
                  </div>
                )}
                {displayName && (
                  <div className="flex justify-between py-4 sm:gap-4 sm:px-6 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500 dark:text-white">
                      Full Name
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-gray-400">
                      {displayName}
                    </dd>
                  </div>
                )}
                {user?.email && (
                  <div className="flex justify-between py-4 sm:gap-4 sm:px-6 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500 dark:text-white">
                      Email address
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-gray-400">
                      {user?.email}
                    </dd>
                  </div>
                )}
              </dl>
            </div>
          </div>
        </div>

        <div className="mt-6">
          <div className="px-4 sm:px-6 lg:px-9">
            <h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
              Actions
            </h3>
          </div>
          <div className="overflow-hidden border border-gray-900/10 lg:m-8 dark:border-gray-100/10">
            <div className="px-4 py-5 sm:p-0 dark:border-gray-100/10">
              <div className="flex justify-between py-4 sm:gap-4 sm:px-6 sm:py-5">
                <dt className="text-sm font-medium text-gray-500 dark:text-white">
                  <div className="">Active account</div>
                  <div className="pt-1 text-xs text-gray-400 dark:text-white">
                    You are signed in as {user?.email}
                  </div>
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-gray-400">
                  <button
                    onClick={() => signOut("local")}
                    className="inline-flex justify-center rounded-sm border border-transparent bg-grae-main px-4 py-2 text-sm text-black hover:bg-grae-dark hover:bg-primary-100 hover:text-white focus:outline-none"
                  >
                    Sign out
                  </button>
                </dd>
              </div>
              <div className="flex justify-between py-4 sm:gap-4 sm:px-6 sm:py-5">
                <dt className="text-sm font-medium text-gray-500 dark:text-white">
                  <div className="">Sessions</div>
                  <div className="pt-1 text-xs text-gray-400 dark:text-white">
                    Logout of all devices where you are signed in
                  </div>
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-gray-400">
                  <button
                    onClick={() => signOut("global")}
                    className="inline-flex justify-center rounded-sm border border-transparent bg-grae-main px-4 py-2 text-sm text-black hover:bg-grae-dark hover:bg-primary-100 hover:text-white focus:outline-none"
                  >
                    Sign out of all devices
                  </button>
                </dd>
              </div>
              <div className="flex justify-between py-4 sm:gap-4 sm:px-6 sm:py-5">
                <dt className="text-sm font-medium text-gray-500 dark:text-white">
                  <div className="">Delete account</div>
                  <div className="pt-1 text-xs text-gray-400 dark:text-white">
                    This action is irreversible
                  </div>
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-gray-400">
                  <button
                    onClick={handleDeleteAccount}
                    className="inline-flex justify-center rounded-sm border border-transparent bg-lilac-main px-4 py-2 text-sm text-black hover:bg-lilac-dark focus:outline-none"
                  >
                    Delete account
                  </button>
                </dd>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ConfirmDeleteModal: React.FC = () => {
  const {
    session: { access_token },
    user,
    signOut,
  } = useGlobalState();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const mutation = useMutation({
    mutationFn: () => deleteUserAccount(user?.id, access_token),
    onSuccess: () => {
      setIsDeleting(false);
      signOut("local");
    },
    onError: () => {
      setIsDeleting(false);
      setError("An error occurred while deleting your account");
    },
  });

  const handleDeleteAccount = () => {
    setIsDeleting(true);
    mutation.mutate();
  };

  return (
    <dialog id="my_modal_5" className="modal modal-bottom sm:modal-middle">
      {isDeleting && <LoadingSpinner />}
      {!isDeleting && (
        <div className="modal-box">
          <h3 className="text-lg font-bold">
            Are you sure you want to permanently delete your account?
          </h3>

          <p className="py-4">
            This action is irreversible and all your data will be lost.
          </p>
          {error && <p className="text-red-500">{error}</p>}
          <div className="modal-action">
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-gray-400">
              <button
                className="inline-flex justify-center rounded-sm border border-transparent bg-lilac-main px-4 py-2 text-sm text-black hover:bg-lilac-dark focus:outline-none"
                onClick={handleDeleteAccount}
              >
                Delete
              </button>
            </dd>
            <form method="dialog">
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-gray-400">
                <button className="inline-flex justify-center rounded-sm border border-transparent bg-grae-main px-4 py-2 text-sm text-black hover:bg-grae-dark hover:bg-primary-100 focus:outline-none">
                  Cancel
                </button>
              </dd>
            </form>
          </div>
        </div>
      )}
    </dialog>
  );
};

export default AccountPage;
