import { validateInvitationCode } from "@/requests/v1";
import { useMutation } from "@tanstack/react-query";
import React from "react";

import AiBrainIcon from "../../assets/AiBrainIcon.svg";

interface InvitationProps {
  size?: "sm" | "lg";
  switchToSignin: () => void;
  switchToSignup: () => void;
}

const InvitationScreen: React.FC<InvitationProps> = ({
  size = "lg",
  switchToSignin,
  switchToSignup,
}) => {
  const [inviteCode, setInviteCode] = React.useState("");
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  // Mutation for validating invite code
  const validateInviteMutation = useMutation({
    mutationFn: () => validateInvitationCode(inviteCode),
    onSuccess: () => switchToSignup(),
    onError: (error) => {
      let errorMessage = "An error occurred";
      if (error.message.includes("403")) {
        errorMessage = "Invalid invitation code";
      }
      setError(errorMessage);
    },
  });

  const handleSignInSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      await validateInviteMutation.mutateAsync();
      // Redirect to signup page
    } catch (error) {
      let errorMessage = "An error occurred";
      if (error instanceof Error) {
        if (error.message.includes("403")) {
          errorMessage = "Invalid invitation code";
        }
      }
      setError(errorMessage);
    }
    setLoading(false);
  };

  return (
    <div
      // className={`flex min-h-full flex-1 items-center justify-center ${size == "lg" ? "px-4" : ""} py-12 ${size === "lg" ? "my-auto" : ""}`}
    >
      <div
        className={`${size === "lg" ? "w-full max-w-sm space-y-10" : "w-full space-y-5 overflow-hidden rounded px-10 py-14 shadow-lg"}`}
      >
        <div>
          {size === "lg" && (
            <img
              className="mx-auto h-10 w-auto rounded-md bg-primary-100"
              src={AiBrainIcon}
              alt="Your Company"
            />
          )}
          <h2 className="text-center text-xl font-bold leading-9 tracking-tight text-gray-900">
            Enter your invitation code to get started
          </h2>
        </div>

        <form onSubmit={handleSignInSubmit}>
          <div className="relative -space-y-px rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300" />
            <div>
              <label htmlFor="email-address" className="sr-only">
                Invitation code
              </label>
              <input
                id="invitation-code"
                name="invitation-code"
                type="text"
                value={inviteCode}
                onChange={(e) => setInviteCode(e.target.value)}
                required
                className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-6"
                placeholder="Invitation code"
              />
            </div>
          </div>

          <div className="flex items-center justify-center">
            <button
              type="submit"
              className="mt-4 flex w-full justify-center rounded-md bg-primary-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
            >
              Submit
            </button>
          </div>

          {error && (
            <div className="space-y-6">
              <div className="text-sm font-semibold text-red-500">{error}</div>
            </div>
          )}

          {loading && (
            <div className="flex items-center justify-center">
              <span className="loading loading-spinner loading-lg"></span>
            </div>
          )}
        </form>

        <div className="flex flex-col space-y-2">
          <p className="text-center text-sm leading-6 text-primary-500">
            Got an account?{" "}
            <button
              onClick={switchToSignin}
              className="font-semibold text-primary-500 hover:text-primary-500"
            >
              Sign In
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default InvitationScreen;
