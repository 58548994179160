import { queryClient } from "@/index";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useGlobalState } from "@/context/GlobalState";
import { classNames } from "@/lib/utils";
import { deleteChatById } from "@/requests";
import { NavigationItem } from "@/types/sidebar";
import { IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { MoreHorizontal } from "lucide-react";
import { forwardRef, useReducer } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

type ChatItemProps = {
  id: string;
  name: string;
};

type MouseHoverState = {
  isChatItemHovering: boolean;
  isMenuOpen: boolean;
};

type MouseHoverAction = {
  type: "HOVER_CHAT_ITEM" | "UNHOVER_CHAT_ITEM" | "OPEN_MENU" | "CLOSE_MENU";
};

const initialState: MouseHoverState = {
  isChatItemHovering: false,
  isMenuOpen: false,
};

const mousePositionReducer = (
  mouseState: MouseHoverState,
  action: MouseHoverAction,
): MouseHoverState => {
  switch (action.type) {
    case "HOVER_CHAT_ITEM":
      return { ...mouseState, isChatItemHovering: true };
    case "UNHOVER_CHAT_ITEM":
      return { ...mouseState, isChatItemHovering: false };
    case "OPEN_MENU":
      return { ...mouseState, isMenuOpen: true };
    case "CLOSE_MENU":
      return { ...mouseState, isChatItemHovering: false, isMenuOpen: false };
    default:
      return mouseState;
  }
};

const ChatItem = ({
  chat,
  resetChatView,
}: {
  chat: NavigationItem;
  resetChatView: () => void;
}) => {
  const { pathname } = useLocation();
  const [mouseState, dispatchMouseAction] = useReducer(
    mousePositionReducer,
    initialState,
  );

  const handleMenuOpen = () => dispatchMouseAction({ type: "OPEN_MENU" });

  const handleMenuClose = () => dispatchMouseAction({ type: "CLOSE_MENU" });

  const showHoverContent =
    mouseState.isChatItemHovering || mouseState.isMenuOpen;

  const chatId = chat.href.split("/").pop();
  const pathId = pathname.split("/").pop();

  return (
    <li
      className={classNames(
        chatId === pathId
          ? "bg-primary-100 text-white"
          : "text-gray-400 hover:bg-primary-100 hover:text-white",
        "block rounded-md px-4 text-sm leading-6 text-gray-400",
      )}
      onMouseEnter={() => dispatchMouseAction({ type: "HOVER_CHAT_ITEM" })}
      onMouseLeave={() => dispatchMouseAction({ type: "UNHOVER_CHAT_ITEM" })}
    >
      <div className="flex items-center justify-between">
        <Link to={`${chat.href}`} onClick={resetChatView} className="truncate">
          {chat.name}
        </Link>
        {showHoverContent && (
          <HoverContent
            chat={{ id: chatId!, name: chat.name }}
            onMouseEnter={handleMenuOpen}
            onMouseLeave={handleMenuClose}
          />
        )}
      </div>
    </li>
  );
};

const HoverContent = ({
  chat,
  onMouseEnter,
  onMouseLeave,
}: {
  chat: ChatItemProps;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}) => {
  return (
    <DropdownMenu
      onOpenChange={(isOpen) => {
        if (isOpen) {
          onMouseEnter();
        } else {
          onMouseLeave();
        }
      }}
    >
      <DropdownMenuTrigger asChild className="cursor-pointer">
        <MoreHorizontal className="h-5 w-5" />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DeleteChatItem chat={chat} onMouseLeave={onMouseLeave} />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const DeletDialog = (chat: ChatItemProps) => {
  return (
    <DialogHeader>
      <DialogTitle className="text-black">Delete chat?</DialogTitle>
      <DialogDescription>
        This action cannot be undone. Are you sure you want to permanently
        delete the chat <strong>"{chat.name}"</strong> and all its messages?
      </DialogDescription>
    </DialogHeader>
  );
};

const DeleteChatItem = ({
  chat,
  onMouseLeave,
}: {
  chat: ChatItemProps;
  onMouseLeave: () => void;
}) => {
  return (
    <DialogItem
      chat={chat}
      triggerChildren={
        <div className="flex items-center justify-between gap-2 text-red-500">
          <IconTrash className="h-5 w-5" />
          <p>Delete</p>
        </div>
      }
      onOpenChange={onMouseLeave}
    >
      <div className="flex items-center gap-2 text-red-600">
        <DeletDialog {...chat} />
      </div>
    </DialogItem>
  );
};

type DialogItemProps = React.ComponentProps<typeof DropdownMenuItem> & {
  triggerChildren: React.ReactNode;
  onOpenChange: () => void;
  onSelect?: () => void;
  chat: ChatItemProps;
};

const DialogItem = forwardRef((props: DialogItemProps, forwardedRef: any) => {
  const { triggerChildren, children, onSelect, onOpenChange, ...itemProps } =
    props;
  const {
    session: { access_token },
  } = useGlobalState();
  const { pathname } = useLocation();
  const pathId = pathname.split("/").pop();
  const navigate = useNavigate();

  const deletion = useMutation({
    mutationFn: () => deleteChatById(props.chat.id, access_token),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["chats"],
      });
      if (pathId === props.chat.id) {
        navigate("/dashboard");
      }
      onOpenChange();
    },
  });
  return (
    <Dialog
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          onOpenChange();
        }
      }}
    >
      <DialogTrigger asChild>
        <DropdownMenuItem
          {...itemProps}
          ref={forwardedRef}
          className="DropdownMenuItem"
          onSelect={(event) => {
            event.preventDefault();
            onSelect && onSelect();
          }}
        >
          {triggerChildren}
        </DropdownMenuItem>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay className="DialogOverlay" />
        <DialogContent className="DialogContent">
          {children}
          <DialogClose asChild>
            <div className="item-center flex justify-end gap-4">
              <Button
                disabled={deletion.isPending}
                variant={"default"}
                onClick={() => onOpenChange()}
              >
                Cancel
              </Button>

              <Button
                disabled={deletion.isPending}
                variant={"destructive"}
                onClick={() => deletion.mutate()}
              >
                {deletion.isPending ? "Deleting..." : "Delete"}
              </Button>
            </div>
          </DialogClose>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
});

export default ChatItem;
