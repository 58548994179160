import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
const humanNumber = require("human-number");

export const APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL!;
export const V1_BACKEND_URL = process.env.REACT_APP_V1_API_URL! + "/v1";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export async function fetcher<JSON = any>(
  input: RequestInfo,
  init?: RequestInit,
): Promise<JSON> {
  const res = await fetch(input, init);

  if (!res.ok) {
    const json = await res.json();
    if (json.error) {
      const error = new Error(json.error) as Error & {
        status: number;
      };
      error.status = res.status;
      throw error;
    } else {
      throw new Error("An unexpected error occurred");
    }
  }

  return res.json();
}

export function formatDate(input: string | number | Date): string {
  const date = new Date(input);
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}

export const hashEmail = (email: string): string => {
  const hash = Buffer.from(email).toString("base64");
  const hashWithoutSpecialChars = hash.replace(/[^a-zA-Z0-9]/g, "");
  return hashWithoutSpecialChars;
};

export function downloadContent(
  content: string,
  filename: string,
  contentType = "text/plain",
) {
  const element = document.createElement("a");
  const file = new Blob([content], { type: contentType });
  element.href = URL.createObjectURL(file);
  element.download = filename;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
}

type TimePeriod = "minutes" | "hours" | "days" | "weeks" | "months";

export function convertToMilliseconds(number: number, period: TimePeriod) {
  switch (period) {
    case "minutes":
      return number * 60 * 1000;
    case "hours":
      return number * 60 * 60 * 1000;
    case "days":
      return number * 24 * 60 * 60 * 1000;
    case "weeks":
      return number * 7 * 24 * 60 * 60 * 1000;
    case "months":
      return number * 30 * 24 * 60 * 60 * 1000; // Approximation, as not all months have 30 days
    default:
      throw new Error("Invalid time period");
  }
}

export const favicon_url_constructor = (url: string): string => {
  try {
    const base_url = url.split("/")[2].split(".").join(".");

    return `https://s2.googleusercontent.com/s2/favicons?domain_url=${base_url}`;
  } catch (error) {
    return "https://s2.googleusercontent.com/s2/favicons?domain_url=example.com";
  }
};

interface ICreateBadgeStyleProps {
  baseTextColor?: string;
  hoverTextColor?: string;
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  textSize?: string;
  fontWeight?: string;
  extraClasses?: string;
}

export const createBadgeStyle = ({
  baseTextColor = 'text-gray-500',
  hoverTextColor = 'text-black',
  backgroundColor = 'bg-transparent',
  hoverBackgroundColor = 'bg-primary-50',
  textSize = 'text-xs',
  fontWeight = 'font-base',
  extraClasses = '',
}: ICreateBadgeStyleProps = {}) => {
  return `badge badge-ghost py-3 flex rounded-md ${backgroundColor} px-1 ${textSize} ${fontWeight} ${baseTextColor} hover:${hoverBackgroundColor} hover:${hoverTextColor} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-100 border-white ${extraClasses}`;
}


export const splitStringUsingRegex = (str: string): string[] => {
  const characters: string[] = [];
  const regex = /[\s\S/]/gu;

  let match

  while ((match = regex.exec(str)) !== null) {
    characters.push(match[0]);
  }
  return characters;
}

export const extractHostname = (url: string) => {
  const pattern =
    /(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,})/;
  const match = url.match(pattern);
  return match ? match[1] : "etf-central.com";
};

export const logoFetcher = (url: string | undefined | null) => {
  if (!url) {
    return "https://img.logo.dev/etf-central.com?token=pk_QniGtrEuQDmb8qlb4sTlAA";
  }
  const brand = extractHostname(url);

  return `https://img.logo.dev/${brand}?token=pk_QniGtrEuQDmb8qlb4sTlAA`
}

export const logError = (error: Error, info: React.ErrorInfo) => {
  console.error(error, info);
};

export const formatNumber = (number: number, formatter: (n: number) => string, dollarSign = false) => {
  const isNegative = number < 0;
  const absoluteNumber = Math.abs(number);

  const formattedNumber = humanNumber(absoluteNumber, formatter);
  const formattedNumberWithDollarSign = dollarSign ? `$${formattedNumber}` : formattedNumber;
  return isNegative ? `-${formattedNumberWithDollarSign}` : formattedNumberWithDollarSign;
}

function preloadImage(src: string) {
  return new Promise<string>(async (resolve, reject) => {
    const img = new Image()
    img.src = src
    img.onload = () => resolve(src)
    img.onerror = reject
  })
}

const imgCache = new Map<string, Promise<string>>()

export function imgSrc(src: string) {
  const imgPromise = imgCache.get(src) ?? preloadImage(src)
  imgCache.set(src, imgPromise)
  return imgPromise
}
