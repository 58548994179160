import { SparklesIcon } from "@heroicons/react/20/solid";
import { PopupButton } from "@typeform/embed-react";
import { TypeAnimation } from "react-type-animation";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";

import { BetaIcon, ChatEtfLogoMix } from "@/components/ui/icons";

import {
  ChatEtfLogo,
  DownloadExcelIcon,
  FactSheetIcon,
  NewsIcon,
  PigIcon,
  RelatedETFsIcon,
  ScreenerIcon,
  ShowChartIcon,
  VolatilityIcon,
  CompareETFsIcon,
} from "../../components/ui/icons";
import BetaWidget from "./BetaWidget";

const Hero: React.FC = () => (
  <div className="flex w-2/3 flex-col items-center gap-y-10">
    <div className="flex flex-row items-baseline">
      <ChatEtfLogoMix className="my-0 w-11/12 translate-x-[10px] translate-y-[20px] max-[520px]:h-28 md:h-32" />
      <BetaIcon className="p-2 sm:-translate-x-7 md:-translate-x-9" />
    </div>
    <p className="text-2xl leading-tight text-white max-[520px]:text-lg min-[520px]:text-2xl min-[774px]:text-4xl min-[1037px]:text-5xl">
      Find and analyze your next ETF <br /> pick with your{" "}
      <span className="font-semibold text-blue-ice">AI chat sidekick</span>.
      <TypeAnimation
        sequence={["", 500]}
        wrapper="span"
        speed={50}
        style={{
          display: "inline-block",
          lineHeight: "5rem",
        }}
        repeat={Infinity}
      />
    </p>
    <div className="flex items-center justify-center sm:gap-2 md:gap-10">
      <ScreenerIcon className="h-10 w-10 text-primary-100" />
      <CompareETFsIcon className="h-10 w-10 text-primary-100" />
      <RelatedETFsIcon className="h-10 w-10 text-primary-100" />
      <ShowChartIcon className="h-10 w-10 text-primary-100" />
      <VolatilityIcon className="h-10 w-10 text-primary-100" />
      <NewsIcon className="h-10 w-10 text-primary-100" />
      <FactSheetIcon className="h-10 w-10 text-primary-100" />
      <DownloadExcelIcon className="h-10 w-10 text-primary-100" />
      <PigIcon className="h-10 w-10 text-primary-100" />
    </div>
  </div>
);

export default function Home() {
  return (
    <div className="isolate h-full bg-primary-950 px-0">
      <div className="flex h-full w-full flex-col items-center justify-center gap-5">
        <Hero />
        <PopupButton
          id="iedrUrY8"
          className="group mt-4 inline-flex items-center justify-center rounded-full border-4 border-transparent bg-blue-ice px-6 py-3 text-2xl font-semibold text-primary-950 transition-all duration-300 hover:border-blue-ice hover:text-primary-950 hover:shadow-[0_0_15px_rgba(59,130,246,1)] focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-900 active:bg-slate-800 active:text-slate-300"
        >
          Chat now
          <SparklesIcon className="ml-2 h-6 w-6" />
        </PopupButton>

        <AuthLinks />
      </div>
      <footer className="absolute bottom-5 right-20 flex items-center justify-center bg-primary-950 p-4 text-xs text-white">
        <p>
          Powered by{" "}
          <a
            href={`https://www.trackinsight.com/services/data-services?utm_source=ChatETF&utm_medium=multiple&utm_campaign=ChatETF`}
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            TrackInsight data
          </a>
        </p>
      </footer>
    </div>
  );
}

const AuthLinks = () => {
  return (
    <div className="flex flex-col pt-4">
      <p className="text-center text-sm font-medium leading-6 text-blue-medium">
        Got an invitation code? <IvitationBtn />
      </p>
      <p className="text-center text-sm font-medium leading-6 text-blue-medium">
        Got an account? <SignIn />
      </p>
    </div>
  );
};

const IvitationBtn = () => {
  return (
    <Dialog>
      <DialogTrigger>
        {" "}
        <button className="font-semibold text-white hover:text-blue-ice">
          Sign Up
        </button>
      </DialogTrigger>
      <DialogContent className="p-0">
        <BetaWidget startScreen="invitation" />
      </DialogContent>
    </Dialog>
  );
};

const SignIn = () => {
  return (
    <Dialog>
      <DialogTrigger>
        {" "}
        <button className="font-semibold text-white hover:text-blue-ice">
          Sign In
        </button>
      </DialogTrigger>
      <DialogContent className="p-0">
        <BetaWidget startScreen="signin" />
      </DialogContent>
    </Dialog>
  );
};
