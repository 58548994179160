import "react-loading-skeleton/dist/skeleton.css";
import "react-loading-skeleton/dist/skeleton.css";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import { classNames } from "../../lib/utils";
import { IGroupedChatNavigationItem } from "../../types/sidebar";

const mockChats: IGroupedChatNavigationItem[] = [
  {
    time_period: "Today",
    chats: [
      {
        name: "1",
        href: "",
      },
      {
        name: "2",
        href: "",
      },
      {
        name: "3",
        href: "",
      },
      {
        name: "1",
        href: "",
      },
      {
        name: "2",
        href: "",
      },
      {
        name: "3",
        href: "",
      },
      {
        name: "1",
        href: "",
      },
      {
        name: "2",
        href: "",
      },
      {
        name: "3",
        href: "",
      },
    ],
  },
  {
    time_period: "Today",
    chats: [
      {
        name: "1",
        href: "",
      },
      {
        name: "2",
        href: "",
      },
      {
        name: "3",
        href: "",
      },
      {
        name: "2",
        href: "",
      },
      {
        name: "3",
        href: "",
      },
    ],
  },
  {
    time_period: "Today",
    chats: [
      {
        name: "1",
        href: "",
      },
      {
        name: "2",
        href: "",
      },
      {
        name: "3",
        href: "",
      },
      {
        name: "3",
        href: "",
      },
      {
        name: "3",
        href: "",
      },
    ],
  },
];

export const DesktopSidebarSkeleton: React.FC = () => {
  return (
    <SkeletonTheme baseColor="#1f5a77" highlightColor="#3780a3">
      <nav className="hide-scroll-bar flex max-h-full flex-1 flex-col overflow-y-auto overflow-x-hidden">
        <div className="flex flex-1 flex-col gap-y-3">
          <ul className="-mx-2 space-y-1">
            {mockChats.map((subItem, index) => {
              return (
                <li key={index}>
                  {/* <h3 className="rounded-md text-xs font-semibold uppercase tracking-wider text-gray-400"> */}
                  <h3
                    className={classNames(
                      "text-gray-400 hover:bg-gray-800 hover:text-white",
                      "block rounded-md py-2 pl-9 pr-2 text-xs text-gray-400",
                    )}
                  >
                    <Skeleton width={45} />
                  </h3>
                  <ul className="mt-1 space-y-1">
                    {subItem.chats.map((_, index) => (
                      <li key={index}>
                        <div
                          className={classNames(
                            "text-gray-400 hover:bg-gray-800 hover:text-white",
                            "block rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-gray-400",
                          )}
                        >
                          <Skeleton />
                        </div>
                      </li>
                    ))}
                  </ul>
                </li>
              );
            })}
          </ul>
        </div>
      </nav>
    </SkeletonTheme>
  );
};
