import { XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";

import { ChatTab } from "../../../context/GlobalState";
import { classNames } from "../../../lib/utils";

interface TabsProps {
  tabs: ChatTab[];
}

const IconTabs: React.FC<TabsProps> = ({ tabs }) => {
  return (
    <div className="flex items-center">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          defaultValue={tabs.find((tab) => tab.isCurrent)?.name}
          className="focus:border-pri mary-500 block w-full rounded-md border-gray-300 focus:ring-primary-500"
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="">
          <nav aria-label="Tabs" className="-mb-px flex">
            {tabs.map((tab, index) => (
              <Tab key={index} {...tab} />
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

const Tab: React.FC<ChatTab> = (tab) => {
  const [isHovered, setIsHovered] = React.useState(false);

  const isChat = tab.name.toLowerCase() === "chat";

  return (
    <div
      key={tab.name}
      aria-current={tab.isCurrent ? "page" : undefined}
      className={classNames(
        tab.isCurrent
          ? "bg-primary-100 text-white"
          : `border-transparent ${!isChat && isHovered ? "text-white hover:bg-primary-100" : "text-gray-500"}`,
        isChat && tab.isCurrent ? "hidden" : "",
        "flex cursor-pointer items-center rounded-badge px-3 text-sm",
      )}
      onMouseEnter={(e) => setIsHovered(true)}
      onMouseLeave={(e) => setIsHovered(false)}
    >
      <div
        onClick={tab.onClick}
        className={classNames(
          tab.isCurrent
            ? "bg-primary-100 text-white"
            : `border-transparent ${!isChat && isHovered ? "text-white" : "text-gray-500"}`,
          isChat && tab.isCurrent ? "hidden" : "",
          "flex cursor-pointer items-center text-sm font-medium",
        )}
      >
        <tab.icon
          aria-hidden="true"
          className={classNames(
            tab.isCurrent
              ? "text-white"
              : !isChat && isHovered
                ? "text-white"
                : "text-gray-400",
            "mr-2 h-4 w-5",
          )}
        />
        <>{isChat ? "Back to chat" : tab.name}</>
        {isChat && (
          <div
            className="mx-4 hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
            aria-hidden="true"
          />
        )}
      </div>
      {!isChat ? (
        <button
          type="button"
          className="-m-2.5 ml-1 p-2.5 text-white hover:text-gray-300"
          onClick={tab.onDelete}
        >
          <span className="sr-only">Remove tab</span>
          <XMarkIcon className="h-4 w-5" aria-hidden="true" />
        </button>
      ) : null}
    </div>
  );
};

export default IconTabs;
