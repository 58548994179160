import { nanoid } from "ai";
import React, { createContext } from "react";
import toast from "react-hot-toast";
import { useLoaderData, useNavigate } from "react-router-dom";

import { supabase } from "../requests/supabase";
import {
  IConfirmationPageStatus,
  IResetPasswordState,
  ISocialCallbackState,
  IUserLoginForm,
  IUserSignUpForm,
} from "../types/authTypes";
import { User } from "@supabase/supabase-js";

interface IAuthStateContext {
  userLoginForm: IUserLoginForm;
  adminSigupForm: {
    email: string;
    password: string;
    confirmPassword: string;
  };
  userSignupForm: IUserSignUpForm;
  emailConirmationState: IConfirmationPageStatus;
  resetPasswordState: IResetPasswordState;
  socialCallbackState: ISocialCallbackState;
  setUserLoginForm: (form: IUserLoginForm) => void;
  setAdminSigupForm: (form: {
    email: string;
    password: string;
    confirmPassword: string;
  }) => void;
  setUserSignupForm: (form: IUserSignUpForm) => void;
  handleAdminSignUpSubmit: (
    e: React.FormEvent<HTMLFormElement> | undefined,
  ) => void;
  handleUserSignUpSubmit: (
    e: React.FormEvent<HTMLFormElement> | undefined,
  ) => void;
  handleSignInSubmit: (e: React.FormEvent<HTMLFormElement> | undefined) => void;
  handleConfirmationCodeChange: (code: string) => void;
  resendConfirmationEmail: () => void;
  handleResetPasswordRequest: (
    e: React.FormEvent<HTMLFormElement> | undefined,
  ) => void;
  setResetPasswordState: (state: IResetPasswordState) => void;
  resetUserPassword: (e: React.FormEvent<HTMLFormElement> | undefined) => void;
  authenticateWithSocialProvider: (provider: "linkedin_oidc") => void;
  setSocialCallbackState: (state: ISocialCallbackState) => void;
  updateUserMetadata: () => void;
}

const initialAuthStateContextState: IAuthStateContext = {
  userLoginForm: {
    email: "",
    password: {
      value: "",
      showPassword: false,
    },
    errors: [],
    isLoading: false,
  },
  adminSigupForm: {
    email: "",
    password: "",
    confirmPassword: "",
  },
  userSignupForm: {
    firstName: "",
    lastName: "",
    email: "",
    password: {
      value: "",
      showPassword: false,
    },
    confirmPassword: {
      value: "",
      showPassword: false,
    },
    errors: [],
    isLoading: false,
    investorType: "",
  },
  emailConirmationState: {
    isResendingEmail: false,
    confirmationCode: "",
    email: "",
    error: "",
    resendEmailStatus: "",
    isLoading: false,
  },
  resetPasswordState: {
    email: "",
    newPassword: "",
    confirmPassword: "",
    errors: [],
    isLoading: false,
    resetPasswordStatus: "",
  },
  socialCallbackState: {
    investorType: "",
    isLoading: false,
    errors: [],
  },
  setAdminSigupForm: () => {},
  setUserSignupForm: () => {},
  setUserLoginForm: () => {},
  handleAdminSignUpSubmit: () => {},
  handleUserSignUpSubmit: () => {},
  handleSignInSubmit: () => {},
  handleConfirmationCodeChange: () => {},
  resendConfirmationEmail: () => {},
  handleResetPasswordRequest: () => {},
  setResetPasswordState: () => {},
  resetUserPassword: () => {},
  authenticateWithSocialProvider: () => {},
  updateUserMetadata: () => {},
  setSocialCallbackState: () => {},
};

export const AuthStateContext = createContext<IAuthStateContext>(
  initialAuthStateContextState,
);

export const useAuthState = () => React.useContext(AuthStateContext);

export const AuthStateProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const userData = useLoaderData() as { user?: User };
  const [userLoginForm, setUserLoginForm] = React.useState<IUserLoginForm>(
    initialAuthStateContextState.userLoginForm,
  );
  const [adminSigupForm, setAdminSigupForm] = React.useState<{
    email: string;
    password: string;
    confirmPassword: string;
  }>(initialAuthStateContextState.adminSigupForm);
  const [userSignupForm, setUserSignupForm] = React.useState<IUserSignUpForm>(
    initialAuthStateContextState.userSignupForm,
  );
  const [emailConirmationState, setEmailConirmationState] =
    React.useState<IConfirmationPageStatus>(
      initialAuthStateContextState.emailConirmationState,
    );
  const [resetPasswordState, setResetPasswordState] =
    React.useState<IResetPasswordState>(
      initialAuthStateContextState.resetPasswordState,
    );
  const [socialCallbackState, setSocialCallbackState] =
    React.useState<ISocialCallbackState>(
      initialAuthStateContextState.socialCallbackState,
    );

  const handleSignInSubmit = async (
    e: React.FormEvent<HTMLFormElement> | undefined,
  ) => {
    e?.preventDefault();
    setUserLoginForm({ ...userLoginForm, errors: [], isLoading: true });
    // Implement the sign in logic here
    try {
      const { error } = await supabase.auth.signInWithPassword({
        email: userLoginForm.email,
        password: userLoginForm.password.value,
      });

      if (error) {
        if (error.message === "Email not confirmed") {
          setEmailConirmationState({
            ...emailConirmationState,
            isResendingEmail: false,
            email: userLoginForm.email,
          });
          return navigate("/auth/confirm-email");
        }
        setUserLoginForm({ ...userLoginForm, errors: [error.message] });
        return;
      }

      navigate(`/dashboard`);
    } catch (error) {
      toast("An error occurred. Please try again.");
      console.debug(error);
    }
    setUserLoginForm({ ...userLoginForm, isLoading: false });
  };

  const handleAdminSignUpSubmit = async (
    e: React.FormEvent<HTMLFormElement> | undefined,
  ) => {
    e?.preventDefault();
    // setIsLoading(true);
    // Implement the sign up logic here
    if (adminSigupForm.password !== adminSigupForm.confirmPassword) {
      toast("Passwords do not match. Please try again.");
      // setIsLoading(false)
      return;
    }

    try {
      const { error } = await supabase.auth.signUp({
        email: adminSigupForm.email,
        password: adminSigupForm.password,
        options: {
          data: {
            role: "admin",
          },
        },
      });

      if (error) {
        error.message === "Validation failed" &&
          toast("Please enter a valid email and password.");
        error.message === "Invalid email" &&
          toast("Invalid email. Please try again.");
        error.message === "Invalid password" &&
          toast("Invalid password. Please try again.");
        throw error;
      }

      toast(
        "Account created successfully. Please verify your email address on Supabase.",
      );
    } catch (error) {
      toast("An error occurred. Please try again.");
      console.debug(error);
    }
    // setIsLoading(false)
  };

  const handleUserSignUpSubmit = async (
    e: React.FormEvent<HTMLFormElement> | undefined,
  ) => {
    e?.preventDefault();
    setUserSignupForm({ ...userSignupForm, errors: [], isLoading: true });
    // Implement the sign up logic here
    if (
      userSignupForm.password.value !== userSignupForm.confirmPassword.value
    ) {
      toast("Passwords do not match. Please try again.");
      setUserSignupForm({
        ...userSignupForm,
        errors: ["Passwords do not match. Please try again."],
        isLoading: false,
      });
      return;
    }

    if (!userSignupForm.investorType) {
      toast("Please select an investor type.");
      setUserSignupForm({
        ...userSignupForm,
        errors: ["Please select an investor type."],
        isLoading: false,
      });
      return;
    }

    try {
      const { error } = await supabase.auth.signUp({
        email: userSignupForm.email,
        password: userSignupForm.password.value,
        options: {
          data: {
            role: "user",
            first_name: userSignupForm.firstName,
            last_name: userSignupForm.lastName,
            investor_type: userSignupForm.investorType,
          },
          emailRedirectTo: `${window.location.origin}/auth/confirm-email`,
        },
      });

      if (error) {
        error.message === "Validation failed" &&
          toast("Please enter a valid email and password.");
        error.message === "Invalid email" &&
          toast("Invalid email. Please try again.");
        error.message === "Invalid password" &&
          toast("Invalid password. Please try again.");
        setUserSignupForm({
          ...userSignupForm,
          errors: [error.message],
          isLoading: false,
        });
        throw new Error(error.message);
      }

      toast(
        "Account created successfully. Please check your email to confirm your account.",
      );

      setEmailConirmationState({
        ...emailConirmationState,
        email: userSignupForm.email,
      });

      navigate("/auth/confirm-email");
    } catch (error) {
      const errorMessage = (error as Error).message;
      toast("An error occurred. Please try again.");
      setUserSignupForm({
        ...userSignupForm,
        errors: [errorMessage],
        isLoading: false,
      });
      console.debug(error);
    }
  };

  const handleConfirmationCodeChange = async (code: string) => {
    setEmailConirmationState({
      ...emailConirmationState,
      confirmationCode: code,
      isLoading: true,
    });

    if (code.length === 6) {
      // Implement the email confirmation logic here
      const { data, error } = await supabase.auth.verifyOtp({
        email: emailConirmationState.email,
        token: code,
        type: "email",
      });

      if (error) {
        toast("An error occurred. Please try again.");
        setEmailConirmationState({
          ...emailConirmationState,
          error: error.message,
          isLoading: false,
        });
        console.debug(error);
        return;
      }

      if (data) {
        toast("Email confirmed successfully. Please sign in to continue.");
        navigate("/dashboard/chat/new");
      }
    }
  };

  const resendConfirmationEmail = async () => {
    // Implement the resend confirmation email logic here
    setEmailConirmationState({
      ...emailConirmationState,
      isResendingEmail: true,
    });
    const { error } = await supabase.auth.resend({
      type: "signup",
      email: emailConirmationState.email,
      options: {
        emailRedirectTo: `${window.location.origin}/auth/confirm-email`,
      },
    });

    if (error) {
      toast("An error occurred. Please try again.");
      setEmailConirmationState({
        ...emailConirmationState,
        error: error.message,
        resendEmailStatus: "error",
        isResendingEmail: false,
      });
      console.debug(error);
      return;
    }

    setEmailConirmationState({
      ...emailConirmationState,
      resendEmailStatus: "success",
      isResendingEmail: false,
    });

    toast("Email confirmation sent successfully. Please check your email.");
  };

  const handleResetPasswordRequest = async (
    e: React.FormEvent<HTMLFormElement> | undefined,
  ) => {
    e?.preventDefault();
    setResetPasswordState({
      ...resetPasswordState,
      errors: [],
      isLoading: true,
      resetPasswordStatus: "",
    });

    try {
      const { data, error } = await supabase.auth.resetPasswordForEmail(
        resetPasswordState.email,
        {
          redirectTo: `${window.location.origin}/auth/reset-password-form`,
        },
      );

      if (error) {
        toast("An error occurred. Please try again.");
        setResetPasswordState({
          ...resetPasswordState,
          errors: [error.message],
          isLoading: false,
        });
        console.debug(error);
        return;
      }

      if (data) {
        toast(
          "Password reset email sent successfully. Please check your email.",
        );
        setResetPasswordState({
          ...resetPasswordState,
          isLoading: false,
          resetPasswordStatus: "success",
        });
      }
    } catch (error) {
      console.debug(error);
      toast("An error occurred. Please try again.");
      setResetPasswordState({
        ...resetPasswordState,
        errors: ["Unkown error occured. Please try again later."],
        isLoading: false,
      });
    }
  };

  const resetUserPassword = async (
    e: React.FormEvent<HTMLFormElement> | undefined,
  ) => {
    e?.preventDefault();
    setResetPasswordState({
      ...resetPasswordState,
      errors: [],
      isLoading: true,
      resetPasswordStatus: "",
    });

    if (resetPasswordState.newPassword !== resetPasswordState.confirmPassword) {
      toast("Passwords do not match. Please try again.");
      setResetPasswordState({
        ...resetPasswordState,
        errors: ["Passwords do not match. Please try again."],
        isLoading: false,
      });
      return;
    }

    try {
      const { data, error } = await supabase.auth.updateUser({
        password: resetPasswordState.newPassword,
      });

      if (error) {
        toast("An error occurred. Please try again.");
        setResetPasswordState({
          ...resetPasswordState,
          errors: [error.message],
          isLoading: false,
        });
        console.debug(error);
        return;
      }

      if (data) {
        toast("Password reset successful.");
        setResetPasswordState({
          ...resetPasswordState,
          isLoading: false,
          resetPasswordStatus: "success",
        });
        navigate("/dashboard");
      }
    } catch (error) {
      console.debug(error);
      toast("An error occurred. Please try again.");
      setResetPasswordState({
        ...resetPasswordState,
        errors: ["Unkown error occured. Please try again later."],
        isLoading: false,
      });
    }
  };

  const authenticateWithSocialProvider = async (provider: "linkedin_oidc") => {
    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider,
        options: {
          scopes: "profile email w_member_social",
          redirectTo: `${window.location.origin}/auth/callback`,
        },
      });

      if (error) {
        toast("An error occurred. Please try again.");
        console.debug(error);
        return;
      }
    } catch (error) {
      console.debug(error);
      toast("An error occurred. Please try again.");
    }
  };

  const updateUserMetadata = async () => {
    if (!userData || !userData?.user) {
      toast("User not found. Please sign in to continue.");
      navigate("/auth/login");
      return;
    }

    try {
      setSocialCallbackState({
        ...socialCallbackState,
        isLoading: true,
        errors: [],
      });

      const newData = {
        investor_type: socialCallbackState.investorType,
      };
      const existingMetadata = userData.user.user_metadata;

      const { data, error } = await supabase.auth.updateUser({
        data: {
          ...existingMetadata,
          ...newData,
        },
      });

      if (error) {
        toast("An error occurred. Please try again.");
        console.debug(error);
        setSocialCallbackState({
          ...socialCallbackState,
          errors: [error.message],
          isLoading: false,
        });
        return;
      }

      if (data) {
        toast("User metadata updated successfully.");
        navigate("/dashboard");
      }
    } catch (error) {
      console.debug(error);
      toast("An error occurred. Please try again.");
      setSocialCallbackState({
        ...socialCallbackState,
        errors: ["Unkown error occured. Please try again later."],
        isLoading: false,
      });
    }
  };

  const sharedState: IAuthStateContext = {
    userLoginForm,
    adminSigupForm,
    userSignupForm,
    emailConirmationState,
    resetPasswordState,
    socialCallbackState,
    setAdminSigupForm,
    setUserSignupForm,
    setUserLoginForm,
    handleAdminSignUpSubmit,
    handleUserSignUpSubmit,
    handleSignInSubmit,
    handleConfirmationCodeChange,
    resendConfirmationEmail,
    handleResetPasswordRequest,
    setResetPasswordState,
    resetUserPassword,
    authenticateWithSocialProvider,
    updateUserMetadata,
    setSocialCallbackState,
  };

  return (
    <AuthStateContext.Provider value={sharedState}>
      {children}
    </AuthStateContext.Provider>
  );
};
