import React, { useState } from "react";
import { Link } from "react-router-dom";

import AiBrainIcon from "../../../assets/AiBrainIcon.svg";
import { useAuthState } from "../../../context/AuthState";
import { SocialAuthDiv } from "../Login";
import { Fieldset, Radio, RadioGroup } from "@headlessui/react";
import { classNames } from "../../../lib/utils";
import { TermsConditions } from "../../public/TermsConditions";
import {
  HideHiddenInputIcon,
  ShowHiddenInputIcon,
} from "../../../components/ui/icons";
import { IInvestorType } from "../../../types/authTypes";

export const hideBtnStyle = "absolute right-6 z-10 mt-2";

interface IUserSignUpProps {
  size?: "sm" | "lg";
  switchView?: () => void;
}

const UserSignUp: React.FC<IUserSignUpProps> = ({
  size = "lg",
  switchView,
}) => {
  const { userSignupForm, setUserSignupForm, handleUserSignUpSubmit } =
    useAuthState();
  const showSocialAuth = process.env.REACT_APP_DEPLOYMENT_ENV !== "production";

  const handleInvestorTypeChange = (value: IInvestorType) => {
    setUserSignupForm({ ...userSignupForm, investorType: value });
  };

  const handleShowPasswordToggle = (type: "password" | "confirmPassword") => {
    setUserSignupForm({
      ...userSignupForm,
      [type]: {
        ...userSignupForm[type],
        showPassword: !userSignupForm[type].showPassword,
      },
    });
  };

  return (
    <>
      <div
        // className={`flex h-full flex-1 items-center justify-center ${size == "lg" ? "px-4" : ""} py-12 ${size === "lg" ? "my-auto" : ""}`}
      >
        <div
          className={`${size === "lg" ? "w-full max-w-sm space-y-10" : "w-full space-y-5 overflow-hidden rounded p-10 shadow-lg"}`}
        >
          <div>
            {size === "lg" && (
              <img
                // className="mx-auto h-10 w-auto"
                className="mx-auto h-10 w-auto rounded-md bg-primary-100"
                src={AiBrainIcon}
                alt="Your Company"
              />
            )}
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Setup Your Account
            </h2>
          </div>
          <form className="space-y-6" onSubmit={handleUserSignUpSubmit}>
            <div className="relative -space-y-px rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300" />
              <div>
                <label htmlFor="first-name" className="sr-only">
                  First Name
                </label>
                <input
                  id="first-name"
                  name="first-name"
                  type="text"
                  autoComplete="given-name"
                  value={userSignupForm.firstName}
                  onChange={(e) =>
                    setUserSignupForm({
                      ...userSignupForm,
                      firstName: e.target.value,
                    })
                  }
                  required
                  className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                  placeholder="First Name"
                />
              </div>
              <div>
                <label htmlFor="last-name" className="sr-only">
                  Last Name
                </label>
                <input
                  id="last-name"
                  name="last-name"
                  type="text"
                  autoComplete="family-name"
                  value={userSignupForm.lastName}
                  onChange={(e) =>
                    setUserSignupForm({
                      ...userSignupForm,
                      lastName: e.target.value,
                    })
                  }
                  required
                  className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                  placeholder="Last Name"
                />
              </div>
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={userSignupForm.email}
                  onChange={(e) =>
                    setUserSignupForm({
                      ...userSignupForm,
                      email: e.target.value,
                    })
                  }
                  required
                  pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                  className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                  placeholder="Email address"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <div className="flex">
                  <input
                    id="password"
                    name="password"
                    type={
                      userSignupForm.password.showPassword ? "text" : "password"
                    }
                    autoComplete="current-password"
                    value={userSignupForm.password.value}
                    onChange={(e) =>
                      setUserSignupForm({
                        ...userSignupForm,
                        password: {
                          ...userSignupForm.password,
                          value: e.target.value,
                        },
                      })
                    }
                    required
                    // pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"
                    className="relative block w-full rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                    placeholder="Password"
                  />
                  <button
                    className={hideBtnStyle}
                    onClick={(e) => {
                      e.preventDefault();
                      handleShowPasswordToggle("password");
                    }}
                  >
                    {userSignupForm.password.showPassword ? (
                      <HideHiddenInputIcon />
                    ) : (
                      <ShowHiddenInputIcon />
                    )}
                  </button>
                </div>
              </div>
              <div>
                <label htmlFor="confirm-password" className="sr-only">
                  Confirm Password
                </label>
                <div className="flex">
                  <input
                    id="confirm-password"
                    name="confirm-password"
                    type={
                      userSignupForm.confirmPassword.showPassword
                        ? "text"
                        : "password"
                    }
                    autoComplete="current-password"
                    value={userSignupForm.confirmPassword.value}
                    onChange={(e) =>
                      setUserSignupForm({
                        ...userSignupForm,
                        confirmPassword: {
                          ...userSignupForm.confirmPassword,
                          value: e.target.value,
                        },
                      })
                    }
                    required
                    className="relative block w-full rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                    placeholder="Confirm-password"
                  />
                  <button
                    className={hideBtnStyle}
                    onClick={(e) => {
                      e.preventDefault();
                      handleShowPasswordToggle("confirmPassword");
                    }}
                  >
                    {userSignupForm.confirmPassword.showPassword ? (
                      <HideHiddenInputIcon />
                    ) : (
                      <ShowHiddenInputIcon />
                    )}
                  </button>
                </div>
              </div>
            </div>

            {userSignupForm.errors.length > 0 && (
              <div className="space-y-6">
                {userSignupForm.errors.map((error, index) => (
                  <div
                    key={index}
                    className="text-sm font-semibold text-red-500"
                  >
                    {error}
                  </div>
                ))}
              </div>
            )}

            <InvestorTypeSelection onChangeHandler={handleInvestorTypeChange} />

            {userSignupForm.investorType && <AcceptTermsAndConditions />}

            {userSignupForm.isLoading ? (
              <div className="flex items-center justify-center">
                <span className="loading loading-spinner loading-lg"></span>
              </div>
            ) : (
              <div>
                <button
                  type="submit"
                  className="flex h-10 w-full items-center justify-center rounded-md bg-primary-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-primary-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                >
                  Create Account
                </button>
              </div>
            )}
          </form>

          {showSocialAuth ? <SocialAuthDiv /> : null}

          <p className="text-center text-sm leading-6 text-primary-500">
            Already a member?{" "}
            {!switchView ? (
              <Link
                to="/auth/login"
                className="font-semibold text-gray-600 hover:text-primary-500"
              >
                Sign In
              </Link>
            ) : (
              <button
                onClick={switchView}
                className="font-semibold text-gray-600 hover:text-primary-500"
              >
                Sign In
              </button>
            )}
          </p>
        </div>
      </div>
    </>
  );
};

export const InvestorTypeSelection: React.FC<{
  onChangeHandler: (value: IInvestorType) => void;
}> = ({ onChangeHandler }) => {
  const [investorTypeSelected, setSelectedInvestorType] =
    useState<IInvestorType>("");

  const handleInvestorTypeChange = (value: IInvestorType) => {
    setSelectedInvestorType(value);
    onChangeHandler(value);
  };

  return (
    <Fieldset aria-label="Server size">
      <div className="mb-4">Select Investor Type:</div>
      <RadioGroup
        aria-required
        value={investorTypeSelected}
        onChange={handleInvestorTypeChange}
        className="grid grid-cols-2 gap-2"
      >
        <Radio
          value={"retail"}
          aria-label="Retail Investor"
          aria-description={`Retail Investor`}
          className={({ focus }) =>
            classNames(
              focus ? "border-primary-500 ring-2 ring-primary-500" : "",
              !focus ? "border-gray-300" : "",
              "relative m-0 block cursor-pointer rounded-lg border bg-white px-6 py-4 text-center shadow-sm focus:outline-none",
            )
          }
        >
          {({ checked, focus }) => (
            <>
              <span className="text-gray-900">Retail Investor</span>
              <span
                className={classNames(
                  checked ? "border-primary-500" : "border-transparent",
                  focus ? "border" : "border-2",
                  "pointer-events-none absolute -inset-px rounded-lg",
                )}
                aria-hidden="true"
              />
            </>
          )}
        </Radio>
        <Radio
          value={"professional"}
          aria-label="Professional Investor"
          aria-description={`Professional Investor`}
          className={({ focus }) =>
            classNames(
              focus ? "border-primary-500 ring-2 ring-primary-500" : "",
              !focus ? "border-gray-300" : "",
              "relative m-0 block cursor-pointer rounded-lg border bg-white px-6 py-4 text-center shadow-sm focus:outline-none",
            )
          }
        >
          {({ checked, focus }) => (
            <>
              <span className="text-gray-900">Professional Investor</span>
              <span
                className={classNames(
                  checked ? "border-primary-500" : "border-transparent",
                  focus ? "border" : "border-2",
                  "pointer-events-none absolute -inset-px rounded-lg",
                )}
                aria-hidden="true"
              />
            </>
          )}
        </Radio>
      </RadioGroup>
    </Fieldset>
  );
};

export const AcceptTermsAndConditions: React.FC = () => {
  return (
    <>
      <div className="mb-4 max-h-52 overflow-scroll">
        <TermsConditions />
      </div>
      <div className="flex items-center">
        <input
          id="terms-and-conditions"
          name="terms-and-conditions"
          type="checkbox"
          className="text-primary-600 h-4 w-4 rounded border-gray-300 focus:ring-primary-500"
          required
        />
        <label
          htmlFor="terms-and-conditions"
          className="ml-2 block text-sm text-gray-900"
        >
          I agree to the Terms and Conditions *
        </label>
      </div>
    </>
  );
};

export default UserSignUp;
