import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

type CurrentView = "chat" | "report-card" | "comparison-card" | "account";

interface CurrentViewState {
    currentView: CurrentView;
    isDiscordWidgetOpen: boolean;
    setCurrentView: (data: CurrentView) => void;
    toggleDiscordWidget: (isOpen: boolean) => void;
}

export const useCurrentViewState = create<CurrentViewState>()(
    devtools(
        persist(
            (set) => ({
                currentView: "chat",
                setCurrentView: (currentView) => set({ currentView }),
                isDiscordWidgetOpen: false,
                toggleDiscordWidget: (isOpen) => set({ isDiscordWidgetOpen: isOpen }),
            })
            , { name: 'currentView' },
        ),
    ),
)